<template>
  <div class="confirmation-multiple">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      title="Confirmer les dossiers"
      @click.prevent="changeConfirmationDossier(true)"
    >
      <font-awesome-icon icon="edit"
    /></b-button>
    <!-- CONFIRMATION Modal -->
    <b-modal
      ref="confirmation-dossier-facture-th-modal"
      id="confirmation-dossier-facture-th-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Confirmation des dossiers '"
      @hidden="hideModal('confirmation-dossier-facture-th-modal')"
      modal-class="modal-extaction-bdd-frais"
    >
      <template v-if="step == 0">
        <div>
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="fieldset-headers-horizontal"
                label="Choisie les supports pour la génération automatique des factures: "
                label-for="confirmation-dossier-facture-th-modal"
              >
                <b-form-checkbox
                  v-model="selectedAllSupport"
                  switch
                  @change="changeCheckSupport"
                  >Tous</b-form-checkbox
                >

                <b-form-checkbox-group
                  switches
                  stacked
                  id="checkbox-group-1"
                  v-model="selectedSupport"
                  :options="listSupport"
                  name="flavour-1"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="form-modal-custom-style mt-2">
          <!-- <div class="messageError"> -->
          <!-- <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div> -->
          <!-- </div> -->
          <div class="actionModel">
            <b-button
              class="button-valide-style "
              @click="changeConfirmationDossier(false)"
            >
              <span>
                Générer
                <div v-if="loading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div></div
      ></template>

      <template v-else>
        <div>
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2 export-facture-auto"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
        <div id="divFactureAuto" ref="divFactureAuto">
          <div
            v-for="(msg, index) in message"
            :key="msg.num_dossier"
            :class="{
              'card-generation-fact': index % 2 == 0,
              'card-generation-fact-active': index % 2 != 0
            }"
            style="break-inside: avoid;"
          >
            <center>
              <b class="mb-2">Numéro de dossier : {{ msg.num_dossier }}</b>
            </center>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.deja_exisit && msg.deja_exisit.length"
            >
              <div>
                <b class="title"
                  >Les supports suivant pour le dossier
                  {{ msg.num_dossier }}
                  ont déjà des factures :
                </b>
              </div>

              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.deja_exisit)">
                  <li v-for="(e, index) in msg.deja_exisit" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.empty_value && msg.empty_value.length"
            >
              <div>
                <b class="title"> Factures avec un montant égale à zéro : </b>
              </div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.empty_value)">
                  <li v-for="(e, index) in msg.empty_value" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.generated && msg.generated.length"
            >
              <div><b class="title"> Les factures générées : </b></div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.generated)">
                  <li v-for="(e, index) in msg.generated" :key="index">
                    {{ e.support }} : {{ e.num }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1 d-block"
              v-if="
                msg && msg.no_or_multiple_rib && msg.no_or_multiple_rib.length
              "
            >
              <div>
                <b class="title">
                  Les supports suivants n'ont pas un RIB où à plusieurs RIB :</b
                >
              </div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.no_or_multiple_rib)">
                  <li v-for="(e, index) in msg.no_or_multiple_rib" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- END CONFIRMATION Modal -->
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import html2pdf from 'html2pdf.js';
export default {
  props: {
    dataToUse: { required: true },
    filterConfirmation: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false,
      listSupport: [
        { value: 'NOVAE', text: 'NOVAE' },
        { value: 'WEB ABONDAN', text: 'PINKMAN' },
        { value: 'ISO 33', text: 'KYNANE technique' },
        { value: 'RGE', text: 'CERTILIA' },
        { value: 'DNH', text: 'GE CDG' },
        { value: 'SAV-S', text: 'SAMI-B' },
        { value: 'CORPEDYS', text: 'CORPEDYS' },
        { value: 'ACTEO', text: 'ALTEO France' },
        { value: 'alteo_control_gestion', text: 'Alteo contrôle qualité' },
        { value: 'NOVAE MAIRIE', text: 'NOVAE MAIRIE' },
        { value: 'GSE', text: 'GSE' },
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'upcube', text: 'UPCUBE TECHNOLOGIES' },
        { value: 'LAV', text: 'LAV' },
        { value: 'AFSP', text: 'AFSP' },
        {
          value: 'AFCONFIRMATION',
          text: 'ALTEO FRANCE Régie Commerciale INTERNE'
        },
        { value: 'AFCOMMERCIAL', text: 'ALTEO FRANCE Support Commercial' },
        // { value: 'IHS', text: 'IHS' },
        { value: 'KYNAN - ISO 33 - ISAUDIT', text: "Bureau d'etude" },
        { value: 'BE B KYNAN', text: 'BE B KYNAN' },
        { value: 'BE B ALTEO', text: 'BE B ALTEO' }
      ],
      selectedSupport: [],
      selectedAllSupport: false,
      step: 0,
      message: null
    };
  },
  methods: {
    ...mapActions(['confirmationMultipleDossierSimulation']),
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divFactureAuto, {
        margin: 1,
        filename: 'rapport-facture.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      });
    },
    changeCheckSupport() {
      this.selectedSupport =
        this.selectedAllSupport == true
          ? this.listSupport.map(item => {
              return item.value;
            })
          : [];
    },
    async changeConfirmationDossier(show) {
      var bodyFormData = new FormData();
      for (let j = 0; j < this.dataToUse.length; j++) {
        if (this.dataToUse[j].check == true) {
          bodyFormData.append('ids[' + [j] + ']', this.dataToUse[j].id);
        }
      }
      let testValue = null;
      for (var pair of bodyFormData.entries()) {
        if (pair[0] && pair[0].includes('ids')) {
          testValue = true;
        }
      }
      if (testValue == null) {
        this.error =
          "Sélectionne au moins un dossier afin d'effectuer un changement de l'etat.";
        this.$alert('', this.error, 'error').then(() => {
          this.error = null;
        });

        return;
      }
      if (show && this.filterConfirmation == 'non') {
        this.$refs['confirmation-dossier-facture-th-modal'].show();
      } else {
        let msg =
          this.filterConfirmation == 'non'
            ? "Êtes-vous sur de vouloir modifier l'état de ces dossiers et génèrent les factures des supports sélectionnés"
            : "Êtes-vous sur de vouloir modifier l'état de ces dossiers";
        this.$confirm('', msg, 'info')
          .then(async () => {
            if (this.filterConfirmation == 'non') {
              for (let j = 0; j < this.selectedSupport.length; j++) {
                bodyFormData.append(
                  'supports[' + [j] + ']',
                  this.selectedSupport[j]
                );
              }
            }
            let showModelDetails = this.selectedSupport.length ? true : false;

            this.loading = true;
            const response = await this.confirmationMultipleDossierSimulation(
              bodyFormData
            );
            if (response.succes) {
              this.loading = false;

              if (this.filterConfirmation == 'non') {
                if (showModelDetails) {
                  this.message = response.message.projects;
                  this.step = 1;
                } else {
                  this.hideModal('confirmation-dossier-facture-th-modal');
                  let text =
                    '<div ><div>Les  dossiers confirmés avec succès  </div>';
                  this.$alert('', text, 'success').then(() => {
                    this.$emit('reloadData');
                  });
                }
              } else {
                this.hideModal('confirmation-dossier-facture-th-modal');
                let text =
                  '<div style="dispaly:flex"><div>Nombre de dossier confirmé  : ' +
                  response.message.confirmed +
                  '\n' +
                  '\n' +
                  '<div>Nombre de dossier non confirmé  : ' +
                  response.message.unconfirmed +
                  '</div>';
                this.$alert('', text, 'success').then(() => {
                  this.$emit('reloadData');
                });
              }
            }
          })
          .catch(() => {});
      }
    },
    hideModal(ref) {
      if (this.step == 1) {
        this.$emit('reloadData');
      }
      this.resetModal();
      this.$refs[ref].hide();
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.step = 0;
      this.selectedSupport = [];
      this.selectedAllSupport = false;
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: green;
  font-size: 14px;
  padding: 5px 7px 5px 9px;
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.card-generation-fact {
  border: 1px solid #e3e3e3;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .body {
    font-size: 11px;
  }
}
.card-generation-fact-active {
  background-color: #f6f5fb;
  border: 1px solid #e0ddf0;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .body {
    font-size: 11px;
  }
}
.export-facture-auto {
  font-size: 17px;
  left: 89%;
  position: relative;
}
</style>
